import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import {
  MobileView,
  isMobile,
} from "react-device-detect";

export default function ImageModal(props) {
  const [isShowing, setIsShowing] = useState(false);

  return (
    <div className={`${isShowing && "col-span-0"} h-36 w-full`}>
      <div
        onClick={() => setIsShowing((isShowing) => !isShowing)}
        className={`${
          isShowing && ""
        } h-36 w-full bg-red-100 bg-no-repeat bg-cover rounded-lg ring-2 ring-gray-200 hover:ring-black/50`}
        style={{ backgroundImage: `url(${props.img.src})` }}
      ></div>
      <Transition
        show={isShowing}
        enter="transition-opacity duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-250"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <section className="absolute top-0 right-0 w-full h-full bg-black/10 backdrop-blur-lg rounded-lg ring-1 ring-gray-300 overflow-hidden">
          <div
            className={`${
              isMobile && "hidden"
            } grid justify-end align-center h-12 w-full bg-black/60`}
          >
            <button onClick={() => setIsShowing((isShowing) => !isShowing)}>
              <XMarkIcon className="w-8 text-white hover:text-white/80" />
            </button>
          </div>
          <div
            className="w-full h-full relative bg-no-repeat bg-center bg-clip-content"
            style={{ backgroundImage: `url(${props.img.src})` }}
          ></div>
          <MobileView>
            <div className="absolute bottom-28 w-full h-24 grid place-items-center ">
              <button
                type="button"
                onClick={() => setIsShowing((isShowing) => !isShowing)}
                className="w-24 h-24 grid place-items-center rounded-full ring-1 ring-white backdrop-blur-sm select-none"
              >
                <XMarkIcon className="w-20 text-white" />
              </button>
            </div>
          </MobileView>
          <div className="absolute w-full  h-24 bottom-0 left-0 backdrop-blur-md bg-black/60 text-white p-3 overflow-hidden">
            {props.img.text}
          </div>
        </section>
      </Transition>
    </div>
  );
}
