import React, {
  Component,
  useContext,
  useEffect,
  Fragment,
  useState,
} from "react";
import _ from 'lodash'
import { Link } from "react-router-dom";
import {
  ArrowRightCircleIcon,
} from "@heroicons/react/24/outline";

import { Popover, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'


import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


function HeroMode(props) {
let attractions = props.place?.attractions;
  return (
    <div className="relative overflow-hidden bg-white pt-24">
      <div className="mx-auto max-w-7xl">
        <div className="grid xs:grid-cols-1 md:grid-cols-2  relative bg-white p-8  w-full ">
     

          <div className="sm:text-center lg:text-left">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
              <span className="block xl:inline">{props.place?.title}</span>{' '}
              <span className="block text-indigo-600 xl:inline">Excursions</span>
            </h1>
            <p className="mt-3 text-base text-gray-500 sm:mx-auto sm:mt-5 sm:max-w-xl sm:text-lg md:mt-5 md:text-xl lg:mx-0">
              {props.place?.description}
            </p>


          </div>


          <div className="w-full h-full  bg-white">

            <Carousel axis="horizontal"  autoPlay infiniteLoop showThumbs={false} showArrows={false} stopOnHover={false} swipeable={false}>
              {attractions.map((attraction) => (
                <div
                  key={attraction.name}
                  className="grid bg-cover bg-center bg-slate-900 bg-no-repeat p-6 shadow-xl rounded-lg h-[400px] place-items-center"
                  style={{ backgroundImage: `url(${attraction.images[_.random(attraction.images.length-1)]})` }}
                >
                  <div className="mt-6 text-white  rounded bg-black/10 p-1 w-fit">
                    <h3 className="text-lg font-extrabold">{attraction.name}</h3>
                  </div>
                </div>
              ))}

            </Carousel>
          </div>
        </div>
      </div>


    </div>
  )
}

export function DestinationFeature(props) {

  let attractions = props.place?.attractions;
  let random_attraction = attractions[_.random(attractions.length-1)]
  let thumbNail = random_attraction.images[_.random(random_attraction.images.length-1)]

  let nav_el = <a
    key={props.place?.title}
    href={props.place?.link}
    className="-m-3 flex items-start rounded-lg p-3 hover:bg-gray-50"
  >
    <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md
      bg-cover bg-gradient-to-r from-sky-600 to-green-600 text-white sm:h-12 sm:w-12"
      style={{ backgroundImage: `url(${thumbNail})` }} />
    <div className="ml-4 relative">
      <p className="text-base font-medium text-gray-900">
        {props.place?.title}
      </p>
      <p className="mt-1 text-sm text-gray-500 h-12 overflow-hidden">
        {props.place?.description}
      </p>
      <div className="h-6 w-full absolute bottom-0 right-0 bg-gradient-to-b from-white/30 to-white" />
    </div>
  </a>

  let el = <div
    id="negril-feature"
    className="bg-gradient-to-r from-sky-800 to-green-700"
  >
    <div className="mx-auto max-w-4xl px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:px-8 lg:pt-24">
      <h2 className="text-3xl font-bold tracking-tight text-white flex flex-col gap-2">
        <Link to={props.place?.link} className="flex flex-row gap-2 place-items-center ">
          <span>#{props.place?.title}</span> <ArrowRightCircleIcon height={36} />
        </Link>

        <span className="h-2 w-full bg-white rounded-full"></span>
      </h2>
      <p className="mt-4 max-w-3xl text-lg text-sky-200">
        {props.place?.description}
      </p>
      <p className="mt-4 max-w-3xl font-extrabold text-lg text-sky-200">
        Activities to explore in {props.place?.title}
      </p>
      <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-6 xs:grid-cols-2 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-6">
        {props.place?.attractions.map((attraction) => (
          <div
            key={attraction.name}
            className="grid bg-cover bg-center items-center p-6 shadow-xl rounded-lg xs:aspect-square overflow-hidden"
            style={{ backgroundImage: `url(${attraction.images[_.random(attraction.images.length-1)]})` }}
          >
            <div className="mt-6 text-white backdrop-blur-sm rounded bg-black/10 p-1">
              <h3 className="text-md font-extrabold uppercase">{attraction.name}</h3>
              <p className="mt-2 text-base xs:hidden md:block">{attraction.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>

  let name_el =
    <li key={props.place?.title}>
      <a

        href={props.place?.link}
        className="text-base text-gray-500 hover:text-gray-900"
      >
        {props.place?.title}
      </a>
    </li>

  let hero_el = <>
    <HeroMode place={props.place} />
  </>

  let elem = el
  if (_.has(props, 'nav')) {
    elem = nav_el;
  }
  if (_.has(props, 'nameOnly')) {
    elem = name_el;
  }
  if (_.has(props, 'hero')) {
    elem = hero_el;
  }
  return elem
};