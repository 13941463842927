import React, { Fragment, Component, useEffect, useState, useContext } from 'react'

import NavigationBar from './NavigationBar'
import { FooterSection } from './FooterSection'
import { FloatingBookBtn } from '../FloatingBookBtn'
import { isBrowser, isMobile } from "react-device-detect";
export class MainLayout extends Component {
    render() {
        return <>
            <div className={`${isMobile ? "w-fit" : "w-full"} bg-white`}>
                <section className="h-screen w-screen relative bg-sky-600 bg-fixed bg-origin-border bg-no-repeat overflow-hidden">
                    <div
                        className="flex-col relative  flex h-screen overflow-hidden overflow-y-auto"
                    >
                        <header className='w-screen fixed z-10'>
                        <NavigationBar />
                        </header>
                        <main>
                            {this.props.children}
                        </main>
                        <FooterSection />
                    </div>
                    <FloatingBookBtn />
                </section>
            </div>
        </>
    }
}